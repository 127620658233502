/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax

Vue.use(Vuesax);

// axios
import axios from "./axios.js";
import infoJson from "../info.json";
Vue.prototype.$http = axios;
Vue.prototype.$infoJson = infoJson;
Vue.prototype.$version = process.env.VUE_APP_VERSION;

// Theme Configurations
import "../themeConfig.js";
import { encryptData, decryptData } from "./utils/helper.js";

import defaultOptions from "@/components/vue-number/options";
import NumberFormat from "@/components/vue-number/number-format";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

import "vue-multiselect/dist/vue-multiselect.min.css";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// i18n
import i18n from "./i18n/i18n";

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

Vue.prototype.$store = store;

// ADDONS Vue-multiselect
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
Vue.component("multiselect", Multiselect);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");
import "vue-select/dist/vue-select.css";

// VeeValidate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);

const isBetween = (value, { min, max } = {}) => {
  value = value
    .toString()
    .replace(/[^.\d]/g, "")
    .toString();
  return Number(min) <= value && Number(max) >= value;
};
// The first param is called 'min', and the second is called 'max'.
const paramNames = ["min", "max"];

VeeValidate.Validator.extend("between", isBetween, {
  paramNames, //  pass it in the extend options.
});

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

// vue cookies
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
Vue.$cookies.config("7d", "/", "", false);

import DataTable from "./components/DataTable.vue";
Vue.component("data-table", DataTable);
import CardDataTable from "./components/CardDataTable.vue";
Vue.component("card-data-table", CardDataTable);
import DataTableNonVS from "./components/DataTableNonVS.vue";
Vue.component("data-table-non-vs", DataTableNonVS);

// vue content placeholder
import VueContentPlaceholders from "vue-content-placeholders";
Vue.use(VueContentPlaceholders);

import moment from "moment";

const secretKey = "IAtUvKCXYp6loqUJ";
Vue.mixin({
  methods: {
    priceFormat: function (amount) {
      const newAmount = amount
        .toString()
        .replace(/[^.\d]/g, "")
        .toString();
      console.log(amount, newAmount);
      amount = parseFloat(newAmount).toFixed(2);

      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    priceFormatNoDecimal: function (amount) {
      const newAmount = amount
        .toString()
        .replace(/[^.\d]/g, "")
        .toString();
      console.log(amount, newAmount);
      amount = parseFloat(newAmount).toFixed(0);

      return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    globalPriceFormat: function (amount) {
      let formatNumber = new NumberFormat(defaultOptions);
      const number = formatNumber.format(amount);
      return number;
    },
    globalPriceUnformat: function (amount) {
      let formatNumber = new NumberFormat(defaultOptions);
      const number = formatNumber.unformat(amount);
      return parseFloat(number);
    },
    globalDateFormat: function (date) {
      if (date) {
        // return moment(date).format('DD/MM/YYYY')
        return moment.utc(date).format("DD/MM/YYYY");
      }
      return "";
    },
    globalIsEmptyObject(obj) {
      return JSON.stringify(obj) === "{}" || obj === null || obj === undefined;
    },
    encryptData: async function (plainText) {
      return await encryptData(plainText, secretKey);
    },
    decryptData: async function (cipherText) {
      return await decryptData(cipherText, secretKey);
    },
    downloadFileAwsS3: function (key) {
      this.$vs.loading();
      this.$http
        .post("/api/v1/general/download", {
          key: key,
        })
        .then((resp) => {
          if (resp.code == 200) {
            console.log("=======resp=====", resp);
            var url = decodeURIComponent(resp.data);
            window.open(url, "_blank").focus();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    globalJsonParse(obj) {
      console.log(obj);
      return JSON.parse(obj);
    },
    globalIsNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    previewFileAwsS3: async function (key) {
      this.$vs.loading();
      try {
        const resp = await this.$http.get("/api/v1/general/preview", {
          params: {
            key: key,
          },
        });
        if (resp.code == 200) {
          const byteCharacters = atob(resp.data.file);
          const byteNumbers = Uint8Array.from(byteCharacters, (char) =>
            char.charCodeAt(0)
          );
          console.log("=============", resp.data.type);
          const blob = new Blob([byteNumbers], {
            type: resp.data.type,
          });
          const url = URL.createObjectURL(blob);
          // window.open(url, "_blank");
          this.$vs.loading.close();
          return url;
        } else {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: resp.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
          return null;
        }
      } catch (error) {
        console.log(error);
        this.$vs.notify({
          title: "Error",
          text: error.message,
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return null;
      }
      // this.$vs.loading();
      // const resp = await this.$http.post("/api/v1/general/preview", {
      //   key: key,
      // });
      // if (resp.code == 200) {

      // }
      // this.$http
      //   .get("/api/v1/general/preview", {
      //     params: {
      //       key: key,
      //     },
      //   })
      //   .then((resp) => {
      //     console.log(resp);
      //     if (resp.code <= 299) {
      //       //convert base64 to string binary data held in a string
      //       const byteCharacters = atob(resp.data.file);

      //       //convert string binary to a binary digits
      //       const byteNumbers = Uint8Array.from(byteCharacters, (char) =>
      //         char.charCodeAt(0)
      //       );

      //       //convert binary digits to a blob
      //       const blob = new Blob([byteNumbers], {
      //         type: resp.data.type,
      //       });

      //       //create a URL for the blob
      //       const url = URL.createObjectURL(blob);

      //       // Open the blob URL in a new tab/window for preview
      //       window.open(url, "_blank");
      //     } else {
      //       this.$vs.notify({
      //         title: "Error",
      //         text: resp.message,
      //         color: "danger",
      //         position: "top-right",
      //         iconPack: "feather",
      //         icon: "icon-check",
      //       });
      //     }
      //     this.$vs.loading.close();
      //   }).catch((error) => {
      //     console.log(error);
      //     this.$vs.loading.close();
      //     this.$vs.notify({
      //       title: "Error",
      //       text: error.message,
      //       color: "danger",
      //       position: "top-right",
      //       iconPack: "feather",
      //       icon: "icon-check",
      //     });
      //   });
    },
    getUrlFileAwsS3: function (key) {
      return new Promise((resolve, reject) => {
        this.$http
          .post("/api/v1/general/download", {
            key: key,
          })
          .then((resp) => {
            if (resp.code == 200) {
              var url = decodeURIComponent(resp.data);
              resp.url = url;
              resolve(resp);
            } else {
              reject(resp);
            }
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
});

/* eslint-disable */
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
