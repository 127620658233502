import moment from "moment";

// dateformat
export const dateFormat = (date, format) => {
	return moment(date).format(format);
};

// currency format IDR 1.000.000
export const currencyFormatIDR = (number) => {
	return new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
	}).format(number);
};

// number format 1.000.000
export const numberFormat = (number) => {
	return new Intl.NumberFormat("id-ID").format(number);
};

// array string to string with separator
export const arrayToString = (array, separator) => {
	if (!array || !separator) {
		return "";
	}
	if (array.length === 0) {
		return "";
	}
	return array.join(separator);
};

// convert "16/04/2024 09:46:04" to "2024-04-16T09:46:04"
export const convertStringToISO = (date) => {
	// date = "16/04/2024 09:46:04" to "2024-04-16T09:46:04"
	let dateOnly = date.split(" ")[0];
	let time = "T" + date.split(" ")[1];
	let newDate = dateOnly.split("/").reverse().join("-");

	return newDate + time;
};

// convert UTC to local time for example: "16/04/2024 09:46:04" to "16/04/2024 16:46:04"
export const convertUTCtoLocal = (date) => {
	return moment.utc(date).local().format("DD/MM/YYYY HH:mm:ss");
};

export const convertUTCtoLocalDateTime = (date) => {
	if (!date) {
		return "-";
	}
	return moment.utc(date).format("DD/MM/YYYY HH:mm:ss");
};

export const convertUTCtoLocalDate = (date) => {
	if (!date) {
		return "-";
	}
	return moment.utc(date).local().format("DD/MM/YYYY");
};

// convert date from 01/01/2024 to 2024-01-01
export const convertDateFormat = (date) => {
	return date.split("/").reverse().join("-");
};

// Fungsi untuk mengenkripsi data menggunakan AES-GCM
export async function encryptData(plainText, secretKey) {
	// Convert secretKey to a Uint8Array
	const keyMaterial = new TextEncoder().encode(secretKey);
	console.log("keyMaterial", keyMaterial);
	console.log("secretKey", secretKey);
	// Derive a key from the secretKey
	const key = await crypto.subtle.importKey(
		'raw',
		keyMaterial,
		'AES-GCM',
		false,
		['encrypt']
	);

	// Generate a random initialization vector (IV)
	const iv = crypto.getRandomValues(new Uint8Array(12));

	// Convert plainText to a Uint8Array
	const encodedText = new TextEncoder().encode(plainText);

	// Encrypt the data
	const encryptedData = await crypto.subtle.encrypt(
		{
			name: 'AES-GCM',
			iv: iv
		},
		key,
		encodedText
	);

	// Convert encryptedData to a base64 string
	const encryptedArray = new Uint8Array(encryptedData);
	const encryptedBase64 = btoa(String.fromCharCode(...encryptedArray));

	// Combine IV and encrypted data (IV + encrypted data)
	const ivBase64 = btoa(String.fromCharCode(...iv));
	return ivBase64 + ':' + encryptedBase64;
}

// Fungsi untuk mendekripsi data
export async function decryptData(encryptedData, secretKey) {
	try {
		// Split IV and encrypted data
		const [ivBase64, encryptedBase64] = encryptedData.split(':');

		// Convert secretKey to a Uint8Array
		const keyMaterial = new TextEncoder().encode(secretKey);

		// Derive a key from the secretKey
		const key = await crypto.subtle.importKey(
			'raw',
			keyMaterial,
			'AES-GCM',
			false,
			['decrypt']
		);

		// Convert IV and encrypted data from base64 to Uint8Array
		const iv = new Uint8Array(atob(ivBase64).split('').map(char => char.charCodeAt(0)));
		const encryptedArray = new Uint8Array(atob(encryptedBase64).split('').map(char => char.charCodeAt(0)));

		// Decrypt the data
		const decryptedData = await crypto.subtle.decrypt(
			{
				name: 'AES-GCM',
				iv: iv
			},
			key,
			encryptedArray
		);

		console.error('Decryption success:', decryptedData);
		// Convert decrypted data back to plain text
		return new TextDecoder().decode(decryptedData);
	} catch (error) {
		console.error('Decryption failed:', error);
		throw error;
	}
}