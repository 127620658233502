const state = {
  taxInvoiceRefence: {},
};

const mutations = {
  setTaxInvoiceRefence(state, value) {
    state.taxInvoiceRefence = value;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
