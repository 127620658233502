const state = {
  selectedInvoices: [],
};

const mutations = {
  setInvoice(state, value) {
    const invoices = [...state.selectedInvoices];
    const index = invoices.findIndex((invoice) => invoice.ID === value.ID);
    // console.log("index", index);
    if (index === -1) {
      invoices.push(value);
    } else {
      invoices.splice(index, 1);
    }
    state.selectedInvoices = invoices;
    // console.log("state.selectedInvoices", state.selectedInvoices);
  },
  resetInvoice(state) {
    state.selectedInvoices = [];
  },
  setSelectedInvoices(state, value) {
    state.selectedInvoices = value;
  },
  checkInvoice(state, value) {
    const invoices = [...state.selectedInvoices];
    const checkAll = value.checkAll;
    const newInvoice = value.invoices;

    for (let i = 0; i < newInvoice.length; i++) {
      const index = invoices.findIndex(
        (invoice) => invoice.ID === newInvoice[i].ID
      );
      if (checkAll) {
        if (index === -1) {
          invoices.push(newInvoice[i]);
        }
      } else {
        if (index !== -1) {
          invoices.splice(index, 1);
        }
      }
    }

    state.selectedInvoices = invoices;
    console.log("state.selectedInvoices", state.selectedInvoices);
  },
};

const getters = {
  getselectedInvoicesIds(state) {
    return state.selectedInvoices.map((invoice) => invoice.ID);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
